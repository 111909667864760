const paymentModeConstants = {
    CARD: 'card',
    BANK_TRANSFER: 'bank_transfer',
    DEBIT_CARD_OR_ONE_INSTALLMENT: 'debit_card_or_one_installment',
} as const;

const paymentHelper = {
    paymentModeConstants,
};

export default paymentHelper;
