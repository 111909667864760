import React, { useCallback, useContext, useMemo } from 'react';

import cartHelper from '../../helpers/cartHelper';
import paymentHelper from '../../helpers/paymentHelper';
import proBuildHelper from '../../helpers/proBuildHelper';
import { Installment } from '../../types/Installment';
import { InstallmentWithoutMPInterest } from '../../types/InstallmentWithoutMPInterest';
import { UserContext } from '../User';
import { CheckoutContext } from './context';

export const useCheckout = () => {
    const [userState] = useContext(UserContext);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    const productsResume = proBuildHelper.formatProductsWithBuilds(userState?.user?.cart?.items ?? []);
    const {
        actualStep,
        shippingData,
        installmentSelected,
        selectedPaymentMode,
        allowedInstallmentOptions,
        setActualStep,
        setShippingData,
        reloadAvailableInstallmentOptions,
        availableInstallmentOptions,
        setInstallmentSelected,
        setSelectedPaymentMode,
        handleResetInstallmentSelected,
        onInstallmentsAllowedByMPChange,
    } = useContext(CheckoutContext);

    const handleInstallmentsChange = (e: React.FormEvent<HTMLInputElement>) => {
        const option = allowedInstallmentOptions.find(({ installments }) => installments === Number(e.currentTarget.value));
        setInstallmentSelected(option ?? undefined);
    };
    const firstAvailableInstallmentsQuantityFoundForCard = useMemo(
        (): number | undefined => availableInstallmentOptions.find(({ installments }) => installments > 1)?.installments,
        [availableInstallmentOptions]
    );

    const getMPInterestForCart = (): number => (installmentSelected ? installmentSelected.MPInterest : 0);

    const minInstallmentsForSelectedPaymentMode = useMemo(() => {
        const isCard = selectedPaymentMode === paymentHelper.paymentModeConstants.CARD;
        const installmentsQuantity = isCard ? firstAvailableInstallmentsQuantityFoundForCard : 1;
        return installmentsQuantity;
    }, [selectedPaymentMode, firstAvailableInstallmentsQuantityFoundForCard]);

    const installmentSelectedOrFirstAvailable = useMemo((): InstallmentWithoutMPInterest | Installment | undefined => {
        if (installmentSelected) return installmentSelected;
        return availableInstallmentOptions.find(({ installments }) => installments === minInstallmentsForSelectedPaymentMode);
    }, [availableInstallmentOptions, installmentSelected, minInstallmentsForSelectedPaymentMode]);

    const getProductPriceWithGFInterest = useCallback(
        (productPrice: number): number => {
            const interest = installmentSelectedOrFirstAvailable?.GFInterestRate;
            return cartHelper.getAmountWithInterest({ amount: productPrice, interest }) as number;
        },
        [installmentSelectedOrFirstAvailable?.GFInterestRate]
    );

    const getTotalPriceWithGFInterestFor = useCallback(
        (installmentsQuantity: number): number | undefined => {
            const installment = availableInstallmentOptions.find(({ installments }) => installments === installmentsQuantity);
            return installment?.totalPriceWithGFInterest;
        },
        [availableInstallmentOptions]
    );

    const productsPriceWithGFInterest = useMemo(() => installmentSelectedOrFirstAvailable?.productsPriceWithGFInterest ?? 0, [installmentSelectedOrFirstAvailable]);

    const totalPriceToShow = useMemo(() => {
        if (installmentSelected) return installmentSelected.totalPrice;
        if (!minInstallmentsForSelectedPaymentMode) return undefined;
        return getTotalPriceWithGFInterestFor(minInstallmentsForSelectedPaymentMode);
    }, [installmentSelected, getTotalPriceWithGFInterestFor, minInstallmentsForSelectedPaymentMode]);

    return {
        actualStep,
        shippingData,
        productsResume,
        allowedInstallmentOptions,
        installmentSelected,
        selectedPaymentMode,
        setActualStep,
        setShippingData,
        firstAvailableInstallmentsQuantityFoundForCard,
        getTotalPriceWithGFInterestFor,
        onAppliedCouponsChanged: reloadAvailableInstallmentOptions,
        handleInstallmentsChange,
        setInstallmentSelected,
        onInstallmentsAllowedByMPChange,
        handleResetInstallmentSelected,
        setSelectedPaymentMode,
        getProductPriceWithGFInterest,
        getMPInterestForCart,
        productsPriceWithGFInterest,
        totalPriceToShow,
    };
};
