/* eslint-disable array-callback-return */
import _ from 'lodash';

import { snackActions } from '../SnackBarUtils';
import paymentHelper from './paymentHelper';

const formatterPesos = new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS',
});

const priceType = {
    list: 'list',
    special: 'special',
};

const methodPriceDictionary = {
    [paymentHelper.paymentModeConstants.CARD]: priceType.special,
    [paymentHelper.paymentModeConstants.BANK_TRANSFER]: priceType.special,
    [paymentHelper.paymentModeConstants.DEBIT_CARD_OR_ONE_INSTALLMENT]: priceType.special,
};

const roundPrice = (v) => (v ? Math.ceil(v) : v);

const getTypeOfPrice = (paymentMode) => methodPriceDictionary[paymentMode] ?? priceType.special;

// TODO: En el futuro, seria ideal no tener que hacer nunca esto, y que sea el back el unico encargado de hacer estos calculos
// Hoy se usa solamente en 2 lugares que bien podrian reemplazarse por el back
const getAmountWithInterest = ({ amount = 0, interest }) => {
    const totalPrice = amount + amount * (interest / 100);
    return roundPrice(totalPrice);
};

const getShippingCost = (shippingCost, isSubsidizedShippingPrice) => (isSubsidizedShippingPrice ? 0 : shippingCost);

// Begin formatters
const formatPrice = (price) => formatterPesos.format(price).replace(/\D00$/, '').replace(/\s/g, '');

const getFormattedShippingCost = (shippingCost = 0) => formatPrice(getShippingCost(shippingCost));

function calculateTotalItemsPrice(cartItems) {
    const totalItemsPrice = cartItems.map((item) => (item.quantity || 0) * (item.price || 0)).reduce((a, b) => a + b, 0);
    return formatPrice(totalItemsPrice);
}

function calculateTotalItems(cartItems = []) {
    const allBuildIds = [];
    const products = [];
    cartItems.forEach((item) => {
        if (item.buildId && item.type !== 'operative_system') {
            allBuildIds.push(item.buildId);
        }
        if (!item.buildId) {
            products.push(item.id);
        }
    });
    const uniqueIds = [...new Set(allBuildIds)];
    return products.length + uniqueIds.length;
}

const toggleOperativeSystemFrom = ({ cartItems, buildId, operativeSystem }) => {
    const newCartItems = [...cartItems];
    const soIndex = newCartItems.findIndex((cartItem) => cartItem.buildId === buildId && cartItem.type === 'operative_system');
    if (soIndex === -1) {
        const so = operativeSystem;
        const soAsProduct = {
            id: so.id,
            quantity: 1,
            price: {
                special: so.price.special.ARS,
            },
            name: so.name,
            code: so.code,
            buildId,
            image: so.images[0],
            type: so.type,
        };
        newCartItems.push(soAsProduct);
        return newCartItems;
    }
    newCartItems.splice(soIndex, 1);
    return newCartItems;
};

const parseProductsToSend = (cartItems) =>
    cartItems.map(({ id, quantity, buildId = null, buildSubItemIndex = null }) => ({
        id,
        quantity,
        buildId,
        buildSubItemIndex,
    }));

/* Por algún motivo en algun lugar tenemos solo price y en otros price.special
La idea de esta función es normalizar eso para poder comparar los productos 
FIXME: Esto evidencia algun problema en las estructuras de datos. No está mal que tengamos 2 estructuras distintas, pero no deberíamos tener que hacer esto.
*/
const getCartItemsSimplifyingPriceStructure = (cartItems) =>
    cartItems.map((cartItem) => {
        const { price, ...rest } = cartItem;
        return {
            ...rest,
            price: price.special ?? price,
        };
    });

const getCartItemsChangedPrice = ({ previousCartItems, newCartItems }) => {
    const singlePricedPreviousCartItems = getCartItemsSimplifyingPriceStructure(previousCartItems);
    const singlePricedNewCartItems = getCartItemsSimplifyingPriceStructure(newCartItems);
    return _.differenceBy(singlePricedPreviousCartItems, singlePricedNewCartItems, 'price');
};
const getMissingProducts = ({ itemsToFindIn, itemsToFind }) => _.differenceBy(itemsToFind, itemsToFindIn, 'id');

const warnIfMissingProducts = (missingProducts) => {
    const singleProducts = missingProducts.filter((product) => product.buildId === null);
    const buildProducts = missingProducts.filter((product) => product.buildId !== null);
    if (singleProducts?.length !== 0) {
        const moreThanOne = missingProducts.length > 1;
        const products = missingProducts.map((product) => product.name).join(', ');
        snackActions.warning(
            `Hubo un cambio en el carrito porque ${moreThanOne ? 'los productos' : 'el producto'} ${products} ya no ${moreThanOne ? 'están' : 'está'} disponible${
                moreThanOne ? 's' : ''
            }. Por favor validalo.`
        );
    }
    if (buildProducts?.length !== 0) {
        snackActions.warning(`Hubo un cambio en el carrito porque algún componente de tu build ya no está disponible.`);
    }
};

const warnIfCartHasChanged = async (newCartItems, previousCartItems = null) => {
    const user = await JSON.parse(localStorage.getItem('user'));

    const previousCartItemsToUse = previousCartItems ?? user.cart?.items ?? [];
    if (user) {
        const missingProducts = getMissingProducts({ itemsToFindIn: newCartItems, itemsToFind: previousCartItemsToUse });
        warnIfMissingProducts(missingProducts);

        const previousItemsWithoutMissingItems = _.differenceBy(previousCartItemsToUse, missingProducts, 'id');

        const productsWithNewPrice = getCartItemsChangedPrice({
            previousCartItems: previousItemsWithoutMissingItems,
            newCartItems,
        });

        if (productsWithNewPrice && productsWithNewPrice.length !== 0 && productsWithNewPrice.length > 1) {
            snackActions.warning('Hubo un cambio en el precio de algunos productos del carrito. Por favor validalos entrando al carrito.');
        }
        if (productsWithNewPrice && productsWithNewPrice.length === 1) {
            snackActions.warning(`Hubo un cambio en el precio de: "${productsWithNewPrice[0].name}". Por favor validalo entrando al carrito.`);
        }
    }
};

function getProvinces() {
    const provinces = [
        { name: 'Buenos Aires', id: 'Buenos Aires' },
        { name: 'Capital Federal', id: 'Capital Federal' },
        { name: 'Catamarca', id: 'Catamarca' },
        { name: 'Chaco', id: 'Chaco' },
        { name: 'Chubut', id: 'Chubut' },
        { name: 'Córdoba', id: 'Cordoba' },
        { name: 'Corrientes', id: 'Corrientes' },
        { name: 'Entre Ríos', id: 'Entre Rios' },
        { name: 'Formosa', id: 'Formosa' },
        { name: 'Jujuy', id: 'Jujuy' },
        { name: 'La Pampa', id: 'La Pampa' },
        { name: 'La Rioja', id: 'La Rioja' },
        { name: 'Mendoza', id: 'Mendoza' },
        { name: 'Misiones', id: 'Misiones' },
        { name: 'Neuquén', id: 'Neuquen' },
        { name: 'Río Negro', id: 'Rio Negro' },
        { name: 'Salta', id: 'Salta' },
        { name: 'San Juan', id: 'San Juan' },
        { name: 'San Luis', id: 'San Luis' },
        { name: 'Santa Cruz', id: 'Santa Cruz' },
        { name: 'Santa Fe', id: 'Santa Fe' },
        { name: 'Santiago del Estero', id: 'Santiago del Estero' },
        { name: 'Tierra del Fuego', id: 'Tierra del Fuego' },
        { name: 'Tucumán', id: 'Tucuman' },
    ];
    return provinces;
}

function createBuildObject(product, type, quantity = 1) {
    const buildImage = product.images && product.images[0] ? product.images[0].url : '';

    const cleanDescription = product.description.substring(product.description.lastIndexOf('<p>') + 3, product.description.lastIndexOf('</p>'));
    const buildToAdd = {
        id: product.id,
        type,
        price: product.price.special.ARS,
        withLicense: true,
        code: product.code,
        name: product.name,
        description: cleanDescription,
        image: buildImage,
        quantity,
    };

    return buildToAdd;
}

const formatCartItemForGAEvent = (cartItem = {}, useSpecialPrice) => {
    const { id, name, type, price, quantity } = cartItem;
    return {
        item_id: id,
        item_name: name,
        item_category: type,
        price: useSpecialPrice ? price.special : price.list,
        quantity,
        index: 0,
    };
};

const formatCartItemsForGAEvent = (cartItems = [], useSpecialPrice) => cartItems.map((item, index) => ({ ...formatCartItemForGAEvent(item, useSpecialPrice), index }));

const cartHelper = {
    roundPrice,
    getTypeOfPrice,
    getAmountWithInterest,
    getFormattedShippingCost,
    formatPrice,
    getProvinces,
    calculateTotalItemsPrice,
    calculateTotalItems,
    createBuildObject,
    toggleOperativeSystemFrom,
    parseProductsToSend,
    warnIfCartHasChanged,
    formatCartItemForGAEvent,
    formatCartItemsForGAEvent,
};

export default cartHelper;
