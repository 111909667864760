import { Box, Button, CircularProgress, Hidden, Modal, Typography } from '@material-ui/core';
import _ from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import API from '../../../api/API';
import { useCheckout } from '../../../contexts/Checkout/useCheckout';
import { useConfig } from '../../../contexts/Config/context';
import { ProductsContext } from '../../../contexts/Products/context';
import { UserContext } from '../../../contexts/User';
import userConstants from '../../../contexts/User/userConstants';
import { helmetCompleteOrderConfig } from '../../../data/seo';
import cartHelper from '../../../helpers/cartHelper';
import { sendPageViewGAEvent } from '../../../helpers/gaHelper';
import handler from '../../../helpers/handler';
import shippingDataHelper from '../../../helpers/shippingDataHelper';
import storageHelper from '../../../helpers/storageHelper';
import useSnackbarGD from '../../../hooks/useSnackbar';
import colors from '../../../theme/colors';
import CartResume from '../../components/CartResume';
import PageContent from '../../components/PageContent';
import Step from '../../components/Step';
import { initialBuyerData, initialOrder } from './constants';
import Billing from './steps/Billing';
import CheckoutLogin from './steps/CheckoutLogin';
import CheckoutRegister from './steps/CheckoutRegister';
import OrderConfirmation from './steps/OrderConfirmation';
import Payment from './steps/Payment';
import Shipping from './steps/Shipping';
import useStyles from './useStyles';

const CompleteOrder = () => {
    const navigate = useNavigate();
    const classes = useStyles();
    const myRef = useRef(null);
    const orderSummaryRef = useRef(null);
    const { fetchConfigs } = useConfig();
    const { showSnackbarMessage } = useSnackbarGD();

    const [state, dispatch] = useContext(UserContext);
    const { actualStep, setActualStep } = useCheckout();
    const { productsDispatch } = useContext(ProductsContext);

    const [showLogin, setShowLogin] = useState(true);
    const [, setShowRegister] = useState(false);
    const [loadingTotalPrice, setLoadingTotalPrice] = useState(false);
    const [loadingShippingPrice, setLoadingShippingPrice] = useState(false);
    const [order, setOrder] = useState(initialOrder);
    const [isDisabled, setIsDisabled] = useState(false);
    const [outOfStockBuilds, setOutOfStockBuilds] = useState(null);
    const [outOfStockProducts, setOutOfStockProducts] = useState(null);
    const [openProBuildOutOfStockModal, setProBuildOutOfStockModal] = useState(false);
    const [openProductsOutOfStockModal, setProductsOutOfStockModal] = useState(false);

    const [buyerData, setBuyerData] = shippingDataHelper.usePersistedData('buyerData', initialBuyerData);
    const { shippingData, setShippingData } = useCheckout();

    useEffect(() => {
        sendPageViewGAEvent();
    }, []);

    useEffect(() => {
        fetchConfigs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!state.user?.cart?.id || state.user?.cart.items.length === 0) {
            navigate('/');
        }
    }, [state.user, navigate]);

    useEffect(() => {
        if (state.user && !state.user.role && actualStep !== 4) {
            setActualStep(0);
        } else {
            setActualStep(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const goToNextStep = async () => {
        if (actualStep === 1) {
            const { user } = state;

            if (user.shippingData) {
                const shippingDataFromUser = await shippingDataHelper.getShippingDataFromUser(user.shippingData);

                setShippingData(shippingDataFromUser);
            }
        }

        if (actualStep === 2) {
            const formattedShippingData = shippingDataHelper.formatShippingDataForUser(shippingData);

            const { user } = state;
            const updatedUser = { ...user, shippingData: formattedShippingData };
            dispatch({ type: userConstants.SET_USER, user: updatedUser });
            storageHelper.setUser(updatedUser);
        }

        setActualStep(actualStep + 1);
    };
    const goToPreviousStep = () => setActualStep(actualStep - 1);

    const openRegister = () => {
        setShowRegister(true);
        setShowLogin(false);
    };

    const openLogin = () => {
        setShowLogin(true);
        setShowRegister(false);
    };

    const renderAuth = () => {
        if (showLogin) {
            return <CheckoutLogin openRegister={openRegister} goToNextStep={goToNextStep} />;
        }
        return <CheckoutRegister openLogin={openLogin} goToNextStep={goToNextStep} />;
    };

    const steps = [
        {
            name: 'Cuenta',
            content: renderAuth(),
        },
        {
            name: 'Facturación',
            content: <Billing buyerData={buyerData} goToNextStep={goToNextStep} setBuyerData={setBuyerData} />,
        },
        {
            name: 'Datos de envío',
            content: (
                <Shipping
                    buyerData={buyerData}
                    goToNextStep={goToNextStep}
                    isDisabled={isDisabled}
                    orderSummaryRef={orderSummaryRef}
                    setIsDisabled={setIsDisabled}
                    setLoadingShippingPrice={setLoadingShippingPrice}
                />
            ),
        },
        {
            name: 'Pago',
            content: (
                <Payment
                    buyerData={buyerData}
                    goToNextStep={goToNextStep}
                    goToPreviousStep={goToPreviousStep}
                    loadingTotalPrice={loadingTotalPrice}
                    setLoadingTotalPrice={setLoadingTotalPrice}
                    setOrder={setOrder}
                    setOutOfStockBuilds={setOutOfStockBuilds}
                    setOutOfStockProducts={setOutOfStockProducts}
                    setProBuildOutOfStockModal={setProBuildOutOfStockModal}
                    setProductsOutOfStockModal={setProductsOutOfStockModal}
                />
            ),
        },
        {
            name: 'Compra finalizada',
            content: <OrderConfirmation order={order} />,
        },
    ];

    const renderSteps = steps.map((step, index) => (
        <Box className={classes.stepItem} key={step.name}>
            <Typography noWrap variant="subtitle2" className={actualStep === index ? classes.actualStep : classes.step}>
                {step.name}
            </Typography>
        </Box>
    ));

    const handleModal = (key) => {
        if (key === 'proBuild') {
            setProBuildOutOfStockModal(!openProBuildOutOfStockModal);
        }
        if (key === 'products') {
            setProductsOutOfStockModal(!openProductsOutOfStockModal);
        }
    };

    const handleProBuildOutOfStock = async () => {
        productsDispatch({
            type: 'UPDATE_BUILD_OUT_OF_STOCK',
            product: {
                key: outOfStockBuilds[0].products[0].type,
            },
        });
        navigate(`/probuilder/${outOfStockBuilds[0].id}`);
    };

    const handleProductsOutOfStock = async (key) => {
        const newCart = state.user.cart;
        const newItems = _.differenceBy(newCart.items, outOfStockProducts, 'id');
        newCart.items = cartHelper.parseProductsToSend(newItems);
        API.carts
            .updateCart(newCart)
            .then((response) => {
                dispatch({ type: userConstants.UPDATE_CART, cart: response.data });
                if (key === 'continuePayment') {
                    handleModal('products');
                }
                if (key === 'continueShopping') {
                    navigate('/productos');
                }
            })
            .catch((error) =>
                handler.handleError({
                    error,
                    userContextDispatch: dispatch,
                    showSnackbarMessage,
                })
            );
    };

    const modalForProBuildBody = (
        <>
            {outOfStockBuilds && (
                <Box className={classes.modal} display="flex" flexDirection="column" alignItems="center">
                    <Box mb={2}>
                        <Typography variant="h4">¡Lo sentimos!</Typography>
                    </Box>
                    <Box mb={2}>
                        <Typography align="center" fontWeight={300}>
                            {outOfStockBuilds?.every((build) => build.products.length > 1)
                                ? 'Algunos de los productos seleccionados no tienen stock, para poder efectuar tu compra debes cambiar tu selección de componentes.'
                                : 'Alguno de los productos seleccionados no tiene stock, para poder efectuar tu compra debes cambiar tu selección de componentes.'}
                        </Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" alignItems="center" my={2}>
                        <Typography variant="h4">{outOfStockBuilds?.every((build) => build.products.length > 1) ? 'Productos sin stock:' : 'Producto sin stock:'}</Typography>
                        <Box display="flex" className={classes.containerProducts}>
                            {outOfStockBuilds &&
                                outOfStockBuilds.map((osBuild) => (
                                    <>
                                        {osBuild.products.map(
                                            (
                                                product //FIXME: me encantaria que en vez de usar product.name usemos algo más uniíco como product.id pero no sé qué data llega acá
                                            ) => (
                                                <Box key={product.name} className={classes.productContainer} display="flex" flexDirection="column" alignItems="center" my={1} mx={2}>
                                                    <Box my={1}>
                                                        <Typography>{product.name}</Typography>
                                                    </Box>
                                                    {product.images?.length > 0 ? <img src={product.images[0].url} alt="product" width={100} height={55} /> : <CircularProgress />}
                                                </Box>
                                            )
                                        )}
                                    </>
                                ))}
                        </Box>
                    </Box>
                    <Box>
                        <Button variant="contained" onClick={handleProBuildOutOfStock}>
                            Volver al probuilder
                        </Button>
                    </Box>
                </Box>
            )}
        </>
    );
    const isCartEmptyAfterHandlingProductsOutOfStock = () => {
        if (outOfStockProducts) {
            const outOfStockProductsIds = outOfStockProducts.map((product) => product.id);
            return state.user?.cart?.items.filter(({ id }) => !outOfStockProductsIds.includes(id));
        }
        return state.user?.cart?.items;
    };

    const modalForProductsBody = (
        <>
            <Box className={classes.modal} display="flex" flexDirection="column" alignItems="center">
                <Box mb={2}>
                    <Typography variant="h4">¡Lo sentimos!</Typography>
                </Box>
                <Box mb={2}>
                    <Typography align="center" fontWeight={300}>
                        {outOfStockProducts?.length > 1
                            ? 'Algunos de los productos seleccionados no tienen stock, los mismos fueron eliminados de tu carrito.'
                            : 'Uno de los productos seleccionados no tiene stock, el mismo fue eliminado de tu carrito.'}
                    </Typography>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center" my={2} sx={{ maxWidth: '100%' }}>
                    <Typography variant="h4">{outOfStockProducts?.length > 1 ? 'Productos sin stock:' : 'Producto sin stock:'}</Typography>
                    <Box display="flex" className={classes.containerProducts}>
                        {outOfStockProducts &&
                            outOfStockProducts.map((osProduct) => (
                                <>
                                    <Box className={classes.productContainer} display="flex" flexDirection="column" alignItems="center" my={1} mx={2}>
                                        <Box my={1}>
                                            <Typography>{osProduct.name}</Typography>
                                        </Box>
                                        {osProduct.images?.length > 0 ? <img src={osProduct.images[0].url} alt="product" width={100} height={55} /> : <CircularProgress />}
                                    </Box>
                                </>
                            ))}
                    </Box>
                </Box>
                <Box display="flex">
                    <Box mr={1}>
                        <Button variant="contained" sx={{ width: 2 }} onClick={() => handleProductsOutOfStock('continueShopping')}>
                            Volver a elegir productos
                        </Button>
                    </Box>

                    <Button variant="contained" disabled={isCartEmptyAfterHandlingProductsOutOfStock()?.length === 0} onClick={() => handleProductsOutOfStock('continuePayment')}>
                        Continuar de todas formas
                    </Button>
                </Box>
            </Box>
        </>
    );

    return (
        <>
            <Helmet>
                <title>{helmetCompleteOrderConfig.title}</title>
                <meta name="robots" content="noindex, nofollow" />
                <meta name="description" content={helmetCompleteOrderConfig.description} />
                <meta property="og:image" content="/GD_blacklogo.png" />
                <meta property="og:title" content={helmetCompleteOrderConfig.title} />
                <meta property="og:description" content={helmetCompleteOrderConfig.description} />
            </Helmet>
            <PageContent breadcrumb={[{ name: 'Easy Builder', path: '/easybuilder' }, { name: 'Carrito', path: '/checkout' }, { name: 'Finalizar compra' }]}>
                <Box className={classes.completeOrderContainer}>
                    <Hidden smDown>
                        <Box className={classes.steps} px={4} display="flex">
                            <Box pl={4} pt={4}>
                                <img src={`/images/completeOrder/step${actualStep}.svg`} height={347} alt={`Paso actual: ${actualStep}`} />
                            </Box>

                            <Box pt={4}>{renderSteps}</Box>
                        </Box>
                    </Hidden>
                    <Hidden mdUp>
                        <Box p={2} className={classes.steps}>
                            <Typography variant="h5" align="center">
                                Completar orden
                            </Typography>
                            <Box className={classes.stepsMobile} display="flex" mt={2}>
                                {steps.map((step, index) => (
                                    <Step
                                        key={step.name}
                                        showName
                                        step={step}
                                        index={index}
                                        actualStep={actualStep}
                                        myRef={myRef}
                                        isLast={index === steps.length - 1}
                                        onClick={setActualStep}
                                        selectedColor={colors.yellow}
                                        selectedSecondaryColor={colors.yellow}
                                        normalColor="#DBDBD5"
                                    />
                                ))}
                            </Box>
                        </Box>
                    </Hidden>
                    <Box className={classes.stepContent} flexGrow={1}>
                        <Box className={classes.completeOrderContent} mx={2}>
                            {steps[`${actualStep}`].content}
                        </Box>
                        {actualStep !== 4 && (
                            <Box>
                                <CartResume
                                    resumeRef={orderSummaryRef}
                                    loadingTotalPrice={loadingTotalPrice}
                                    loadingShippingPrice={loadingShippingPrice}
                                    showDetailsButton
                                    showShippingCosts
                                    showFreeShippingBanner
                                />
                            </Box>
                        )}
                        {actualStep === 1 && (
                            <Box className={classes.actionButtons} flexGrow={1} m={2}>
                                <Button className={classes.stepChangeButton} form="billingForm" type="submit" variant="contained">
                                    Siguiente
                                </Button>
                            </Box>
                        )}
                        {actualStep === 2 && (
                            <Box className={classes.actionButtons} flexGrow={1}>
                                <Button className={classes.stepChangeButton} disabled={isDisabled} onClick={goToPreviousStep} variant="outlined">
                                    Anterior
                                </Button>
                                <Button className={classes.stepChangeButton} disabled={isDisabled} form="shippingForm" type="submit" variant="contained">
                                    Siguiente
                                </Button>
                            </Box>
                        )}
                    </Box>
                    <Modal open={openProBuildOutOfStockModal} onClose={() => handleModal('proBuild')}>
                        {modalForProBuildBody}
                    </Modal>
                    <Modal open={openProductsOutOfStockModal} onClose={() => handleModal('products')}>
                        {modalForProductsBody}
                    </Modal>
                </Box>
            </PageContent>
        </>
    );
};
export default CompleteOrder;
